var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.SVG)?_c('img',{attrs:{"data-src":_vm.image.url,"alt":_vm.image.alt ? _vm.image.alt : ''}}):(_vm.images.length > 0)?_c('div',{staticClass:"lazy-image-container"},[_c('picture',{ref:"picture",staticClass:"lazy-image",class:{
      loaded: !_vm.loading || _vm.forceShow,
      ready: _vm.ready,
      'no-lazy': _vm.forceShow,
    },style:(_vm.style),attrs:{"id":_vm.lazyImageId,"data-alt":_vm.image.alt ? _vm.image.alt : ''}},[_vm._l((_vm.images),function(img,index){return _c('source',{key:img.image.url + index,attrs:{"srcset":_vm.getSrcSet(img.image, img.widths || [], img.imageCdn),"media":img.media.type === 'min-max'
          ? `(min-width: ${img.media.min}px) and (max-width: ${img.media.max}px)`
          : `(${img.media.type}-width: ${img.media.value}px)`,"sizes":img.sizes}})}),_vm._v(" "),(_vm.forceShow)?_c('img',{attrs:{"alt":_vm.image.alt ? _vm.image.alt : ''}}):_vm._e()],2),_vm._v(" "),(_vm.imagesHasMq)?_c('div',{attrs:{"id":`lazyImageStyle-${_vm._uid}`},domProps:{"innerHTML":_vm._s(_vm.imagesStyle)}}):_vm._e()]):(_vm.image && _vm.image.url)?_c('div',{staticClass:"lazy-image-container"},[_c('picture',{ref:"picture",staticClass:"lazy-image",class:{ loaded: !_vm.loading, ready: _vm.ready },style:(_vm.style),attrs:{"data-alt":_vm.image.alt ? _vm.image.alt : ''}},[_c('source',{attrs:{"srcset":_vm.imageSrcSet,"sizes":_vm.sizes}}),_vm._v(" "),(_vm.forceShow)?_c('img',{attrs:{"alt":_vm.image.alt ? _vm.image.alt : ''}}):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }